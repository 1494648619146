import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container, Spinner } from 'reactstrap';
import Section1 from "../../project-pages/dashboard/Section1";
import getApi from "../../apis/get.api";
import { checkPermission, getSystemCurrency, handleError } from 'helpers/commonFunctions';

const Dashboard = () => {
    document.title = "ClvrPay.com | Dashboard";
    const [balance, setBalance] = useState<number>(0);
    const [secondlyBalance, setSecondlyBalance] = useState<number>(0);
    const [balanceMad, setBalanceMad] = useState<number>(0);
    const [secondlyBalanceMad, setSecondlyBalanceMad] = useState<number>(0);
    const [report, setReport] = useState<any>(null);    
    const canViewPage = checkPermission("/dashboard");

    useEffect(() => {
        if(!canViewPage){
            window.location.href = '/403'
        }
        getApi('/merchant/dashboard').then((response) => {
            setBalance(response.data.data.balance)
            setSecondlyBalance(response.data.data.secondlyBalance)
            setBalanceMad(response.data.data.balanceMad)
            setSecondlyBalanceMad(response.data.data.secondlyBalanceMad)
            setReport(response.data.data.report)
        }).catch((error) => {
            handleError(error);
        })
    }, [canViewPage]);

    return (
        <div className="page-content">
            <Container fluid={true}>
                {!canViewPage && <div className="display-5">You don't have permission to view this page</div>}
                {canViewPage && <div className="rounded-3">
                    <Section1 balance={balance} secondlyBalance={secondlyBalance} changeDate={() => { }} />
                    {!report && <div className={"p-5 text-center"}><Spinner /></div>}
                    {report  && <Card>
                        <CardHeader>
                            Balance Report
                        </CardHeader>
                        <CardBody>
                            {report && <div className="table-responsive table-card card-body">
                                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Balance To Withdrawal</th>
                                            <th>Pre-loaded Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{getSystemCurrency()} {balance}</td>
                                            <td>{getSystemCurrency()} {secondlyBalance}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>}
                        </CardBody>
                    </Card>}
                    {report && <Card>
                        <CardHeader>
                            Vouchers Report
                        </CardHeader>
                        <CardBody>
                            {report && <div className="table-responsive table-card card-body">
                                <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Active</th>
                                            <th>Redeemed</th>
                                            <th>Deactivated</th>
                                            <th>Expired</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{getSystemCurrency()} {report.active ?? 0}</td>
                                            <td>{getSystemCurrency()} {report.redeemed ?? 0}</td>
                                            <td>{getSystemCurrency()} {report.deactivated ?? 0}</td>
                                            <td>{getSystemCurrency()} {report.expired ?? 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>}
                            {!report && <Spinner />}
                        </CardBody>
                    </Card>}
                </div>}
            </Container>
        </div>
    );
};

export default Dashboard;
